/* COLORS */

.CodeMirror-foldmarker {
  border-radius: 4px;
  background: #08f;
  background: linear-gradient(#43a8ff, #0f83e8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: arial;
  font-size: 12px;
  line-height: 0;
  margin: 0 3px;
  padding: 0px 4px 1px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.1);
}

/* Force content-box sizing for the elements where we expect it */

.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-gutters {
  white-space: nowrap;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.CodeMirror-gutter {
  display: inline-block;
  height: 100%;
  max-width: 100%;
  margin-bottom: -30px;
  vertical-align: top;
  white-space: normal;
  /* Hack to make IE7 behave */
  *zoom: 1;
  *display: inline;
}

/* The lint marker gutter */

.CodeMirror-lint-markers {
  width: 16px;
}

.type-name {
  text-decoration: none;
}

.CodeMirror {
  overflow: hidden;
}

.CodeMirror-lines {
  margin-left: 5px;
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
  padding: 8px 0;
}

.CodeMirror-scroll {
  height: 100%;
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-left: 10px;
  margin-bottom: -30px;
  margin-right: -30px;
  outline: none; /* Prevent dragging from highlighting the element */
  overflow: scroll !important; /* Things will break if this is overridden */
  padding-bottom: 30px;
  position: relative;
  overflow-scrolling: touch;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #555;
  text-decoration: underline;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f00;
}

/* FieldAlias */

.cm-qualifier {
  color: #1c92a9;
}

/* Boolean */

.cm-builtin {
  color: #d47509;
}

/* EnumValue */

.cm-string-2 {
  color: #0b7fc7;
}

/* Directive */

.cm-meta {
  color: #b33086;
}

/* PADDING */

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* Shown when moving in bi-directional text */

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursor {
  background: #7e7;
  border: 0;
  width: auto;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-animate-fat-cursor {
  animation: blink 1.06s steps(1) infinite;
  border: 0;
  width: auto;
}

@keyframes blink {
  0% {
    background: #7e7;
  }
  50% {
    background: none;
  }
  100% {
    background: #7e7;
  }
}

/* Can style cursor different in overwrite (non-insert) mode */

div.CodeMirror-overwrite div.CodeMirror-cursor {
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
}

.cm-s-default .cm-variable-3 {
  color: #085;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header,
.cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

/*.CodeMirror-activeline-background {*/
/*  background: #e8f2ff;*/
/*}*/

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  display: none;
  position: absolute;
  z-index: 6;
}

.CodeMirror-vscrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  top: 0;
}

/***/

.CodeMirror-hint-information .content {
  box-orient: vertical;
  color: #141823;
  display: flex;
  font-family: system, -apple-system, "San Francisco", ".SFNSDisplay-Regular", "Segoe UI", Segoe, "Segoe WP",
    "Helvetica Neue", helvetica, "Lucida Grande", arial, sans-serif;
  font-size: 13px;
  line-clamp: 3;
  line-height: 16px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
}

.CodeMirror-hint-information .content p:first-child {
  margin-top: 0;
}

.CodeMirror-hint-information .content p:last-child {
  margin-bottom: 0;
}

.CodeMirror-hint-information .infoType {
  color: #ca9800;
  cursor: pointer;
  display: inline;
  margin-right: 0.5em;
}

.autoInsertedLeaf.cm-property {
  animation-duration: 6s;
  animation-name: insertionFade;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  border-radius: 2px;
  margin: -2px -4px -1px;
  padding: 2px 4px 1px;
}

@keyframes insertionFade {
  from,
  to {
    background: rgba(255, 255, 255, 0);
    border-color: rgba(255, 255, 255, 0);
  }

  15%,
  85% {
    background: #fbffc9;
    border-color: #f0f3c0;
  }
}

div.CodeMirror-lint-tooltip {
  background-color: white;
  border-radius: 2px;
  border: 0;
  color: #141823;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 16px;
  max-width: 430px;
  opacity: 0;
  padding: 8px 10px;
  transition: opacity 0.15s;
  white-space: pre-wrap;
}

div.CodeMirror-lint-tooltip > * {
  padding-left: 23px;
}

div.CodeMirror-lint-tooltip > * + * {
  margin-top: 12px;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  position: absolute;
  z-index: 4;
}

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linewidget {
  overflow: auto;
  position: relative;
  z-index: 2;
}

.CodeMirror-measure {
  height: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  width: 100%;
}

.CodeMirror-cursor {
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  position: relative;
  visibility: hidden;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4);
}

/* IE7 hack to prevent it from returning funny offsetTops on the spans */

/*.CodeMirror span {*/
/*  vertical-align: text-bottom;*/
/*}*/

/* Used to force a border model for a node */

.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */

.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */

span.CodeMirror-selectedtext {
  background: none;
}

.CodeMirror-dialog {
  background: inherit;
  color: inherit;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 0.1em 0.8em;
  position: absolute;
  z-index: 15;
}

.CodeMirror-dialog-top {
  border-bottom: 1px solid #eee;
  top: 0;
}

.CodeMirror-dialog-bottom {
  border-top: 1px solid #eee;
  bottom: 0;
}

.CodeMirror-dialog input {
  background: transparent;
  border: 1px solid #d3d6db;
  color: inherit;
  font-family: monospace;
  outline: none;
  width: 20em;
}

.CodeMirror-dialog button {
  font-size: 70%;
}

.CodeMirror-foldmarker {
  color: blue;
  cursor: pointer;
  font-family: arial;
  line-height: 0.3;
  text-shadow: #b9f 1px 1px 2px, #b9f -1px -1px 2px, #b9f 1px -1px 2px, #b9f -1px 1px 2px;
}

.CodeMirror-foldgutter {
  width: 1.6em;
}

.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  cursor: pointer;
}

.CodeMirror-foldgutter-open:after {
  content: "\25BE";
}

.CodeMirror-foldgutter-folded:after {
  content: "\25B8";
}

.CodeMirror-info {
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  color: #555;
  font-family: system, -apple-system, "San Francisco", ".SFNSDisplay-Regular", "Segoe UI", Segoe, "Segoe WP",
    "Helvetica Neue", helvetica, "Lucida Grande", arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 8px -8px;
  max-width: 400px;
  opacity: 0;
  overflow: hidden;
  padding: 8px 8px;
  position: fixed;
  transition: opacity 0.15s;
  z-index: 50;
}

.CodeMirror-info :first-child {
  margin-top: 0;
}

.CodeMirror-info :last-child {
  margin-bottom: 0;
}

.CodeMirror-info p {
  margin: 1em 0;
}

.CodeMirror-info .info-description {
  color: #777;
  line-height: 16px;
  margin-top: 1em;
  max-height: 80px;
  overflow: hidden;
}

.CodeMirror-info .info-deprecation {
  background: #fffae8;
  box-shadow: inset 0 1px 1px -1px #bfb063;
  color: #867f70;
  line-height: 16px;
  margin: -8px;
  margin-top: 8px;
  max-height: 80px;
  overflow: hidden;
  padding: 8px;
}

.CodeMirror-info .info-deprecation-label {
  color: #c79b2e;
  cursor: default;
  display: block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  padding-bottom: 5px;
  text-transform: uppercase;
  -webkit-user-select: none;
          user-select: none;
}

.CodeMirror-info .info-deprecation-label + * {
  margin-top: 0;
}

.CodeMirror-info a {
  text-decoration: none;
}

.CodeMirror-info a:hover {
  text-decoration: underline;
}

.CodeMirror-info .type-name {
  color: #ca9800;
}

.CodeMirror-info .field-name {
  color: #1f61a0;
}

.CodeMirror-info .enum-value {
  color: #0b7fc7;
}

.CodeMirror-info .arg-name {
  color: #8b2bb9;
}

.CodeMirror-info .directive-name {
  color: #b33086;
}

.CodeMirror-jump-token {
  text-decoration: underline;
  cursor: pointer;
}

.CodeMirror-lint-tooltip {
  background-color: infobackground;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid black;
  color: infotext;
  font-family: monospace;
  font-size: 10pt;
  max-width: 600px;
  opacity: 0;
  overflow: hidden;
  padding: 2px 5px;
  position: fixed;
  transition: opacity 0.4s;
  white-space: pre-wrap;
  z-index: 100;
}

.CodeMirror-lint-mark-error,
.CodeMirror-lint-mark-warning {
  background-position: left bottom;
  background-repeat: repeat-x;
}

.CodeMirror-lint-mark-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==");
}

.CodeMirror-lint-mark-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJFhQXEbhTg7YAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAMklEQVQI12NkgIIvJ3QXMjAwdDN+OaEbysDA4MPAwNDNwMCwiOHLCd1zX07o6kBVGQEAKBANtobskNMAAAAASUVORK5CYII=");
}

.CodeMirror-lint-marker-error,
.CodeMirror-lint-marker-warning {
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  position: relative;
  vertical-align: middle;
  width: 16px;
}

.CodeMirror-lint-message-error,
.CodeMirror-lint-message-warning {
  background-position: top left;
  background-repeat: no-repeat;
  padding-left: 18px;
}

.CodeMirror-lint-marker-error,
.CodeMirror-lint-message-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAHlBMVEW7AAC7AACxAAC7AAC7AAAAAAC4AAC5AAD///+7AAAUdclpAAAABnRSTlMXnORSiwCK0ZKSAAAATUlEQVR42mWPOQ7AQAgDuQLx/z8csYRmPRIFIwRGnosRrpamvkKi0FTIiMASR3hhKW+hAN6/tIWhu9PDWiTGNEkTtIOucA5Oyr9ckPgAWm0GPBog6v4AAAAASUVORK5CYII=");
}

.CodeMirror-lint-marker-warning,
.CodeMirror-lint-message-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAANlBMVEX/uwDvrwD/uwD/uwD/uwD/uwD/uwD/uwD/uwD6twD/uwAAAADurwD2tQD7uAD+ugAAAAD/uwDhmeTRAAAADHRSTlMJ8mN1EYcbmiixgACm7WbuAAAAVklEQVR42n3PUQqAIBBFUU1LLc3u/jdbOJoW1P08DA9Gba8+YWJ6gNJoNYIBzAA2chBth5kLmG9YUoG0NHAUwFXwO9LuBQL1giCQb8gC9Oro2vp5rncCIY8L8uEx5ZkAAAAASUVORK5CYII=");
}

.CodeMirror-lint-marker-multiple {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAMAAADzjKfhAAAACVBMVEUAAAAAAAC/v7914kyHAAAAAXRSTlMAQObYZgAAACNJREFUeNo1ioEJAAAIwmz/H90iFFSGJgFMe3gaLZ0od+9/AQZ0ADosbYraAAAAAElFTkSuQmCC");
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.CodeMirror-hints {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
  font-family: "Consolas", "Inconsolata", "Droid Sans Mono", "Monaco", monospace;
  font-size: 13px;
  list-style: none;
  margin-left: -6px;
  margin: 0;
  max-height: 14.5em;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.CodeMirror-hint {
  border-top: solid 1px #f7f7f7;
  color: #141823;
  cursor: pointer;
  margin: 0;
  max-width: 300px;
  overflow: hidden;
  padding: 2px 6px;
  white-space: pre;
}

li.CodeMirror-hint-active {
  background-color: #08f;
  border-top-color: white;
  color: white;
}

.CodeMirror-hint-information {
  border-top: solid 1px #c0c0c0;
  max-width: 300px;
  padding: 4px 6px;
  position: relative;
  z-index: 1;
}

.CodeMirror-hint-information:first-child {
  border-bottom: solid 1px #c0c0c0;
  border-top: none;
  margin-bottom: -1px;
}

.CodeMirror-hint-deprecation {
  background: #fffae8;
  box-shadow: inset 0 1px 1px -1px #bfb063;
  color: #867f70;
  font-family: system, -apple-system, "San Francisco", ".SFNSDisplay-Regular", "Segoe UI", Segoe, "Segoe WP",
    "Helvetica Neue", helvetica, "Lucida Grande", arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px;
  max-height: 80px;
  overflow: hidden;
  padding: 6px;
}

.CodeMirror-hint-deprecation .deprecation-label {
  color: #c79b2e;
  cursor: default;
  display: block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  padding-bottom: 5px;
  text-transform: uppercase;
  -webkit-user-select: none;
          user-select: none;
}

.CodeMirror-hint-deprecation .deprecation-label + * {
  margin-top: 0;
}

.CodeMirror-hint-deprecation :last-child {
  margin-bottom: 0;
}

